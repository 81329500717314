
































































































import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { Bind, Debounce } from 'lodash-decorators'
import { MetaInfo } from 'vue-meta'
import { Component, Mixins, Watch } from 'vue-property-decorator'

// mixins
import NotifyMixin from '@/mixins/NotifyMixin'
// store
import MasterOrdersModule from '@/store/modules/master/orders'
// components
import Agreement from '@/components/_uikit/controls/Agreement.vue'
import AttachSocialsModal from '@/components/modals/AttachSocialsModal.vue'
import OrderPaymentMethodModal from '@/components/modals/orders/OrderPaymentMethodModal.vue'
import OrderProductsForm from '@/components/forms/order/OrderProductsForm.vue'
import Select from '@/components/_uikit/controls/Select.vue'
import Tag from '@/components/_uikit/Tag.vue'
// types
import {
  IOrdersProfileFilter,
  MasterOrderLargeResource,
  MasterOrderShortResource,
  MasterOrderShortResourcePaginator,
  NameValueResource,
  OrderStatus,
  UserLargeResource,
  SocialResource, AgreementsTypeEnum,
} from '@/store/types'
import AuthModule from '@/store/modules/auth'
import ProfileModule from '@/store/modules/profile'
import { GET_DEFAULT_TABLE_FILTER } from '@/store'
import { OffersLink } from '@/store/types/docs'

enum StateOfOrdersList {
  TURN_OUT = 'Показать еще',
  TURN_UP = 'Свернуть',
}

type HrefSubject = {
  [key: number]: string,
}

interface IAgreements {
  uuid: string,
  value: {
    agreementOffer: boolean,
  },
}

@Component({
  components: {
    Agreement,
    AttachSocialsModal,
    OrderPaymentMethodModal,
    OrderProductsForm,
    Select,
    Tag,
    ValidationObserver,
    ValidationProvider,
  },
})
export default class Payments extends Mixins(NotifyMixin) {
  private get information () {
    return ProfileModule.information
  }

  private socials: SocialResource[] = []
  private showAttachSocialsModal = false
  private openedOrders: number[] = []
  private agreements: IAgreements[] = []
  private ordersFull: MasterOrderShortResource[] = []
  private orderMoreInfo: { [key: number]: MasterOrderLargeResource } = {}
  private years: NameValueResource[] = [{ name: 'Все', value: '' }]

  private hrefOffer: HrefSubject = OffersLink
  private showOrderPaymentMethodModal = false
  private orderIDSelected: number | null = null
  private orderUUIDSelected: string | null = null

  private mounted() {
    if (!this.information) {
      ProfileModule.fetchInformation()
        .then((response: UserLargeResource) => {
          this.socials = response.socials
          this.showAttachSocialsModal = this.socials.length < ProfileModule.requiredSocials.length
        })
        .catch(this.notifyError)
    } else {
      this.socials = this.information.socials
      this.showAttachSocialsModal = this.socials.length < ProfileModule.requiredSocials.length
    }

    ProfileModule.fetchRegistratedOrdersInformation()
      .then((commonOrdersInfo) => {
        commonOrdersInfo.years.forEach(year => {
          this.years.push({
            name: year.toString(),
            value: year.toString(),
          })
        })
      })
      .catch(this.notifyError)
  }

  private get buttonMessage () {
    if (this.filter.page !== this.ordersMeta.lastPage) {
      return StateOfOrdersList.TURN_OUT
    }
    return StateOfOrdersList.TURN_UP
  }

  private get isLocalTimezone() {
    return AuthModule.isLocalTimezone
  }

  private baseUrl = process.env.VUE_APP_BACKEND_ENDPOINT

  private sortOrder = [
    { name: 'Сначала новые', value: 'desc' },
    { name: 'Сначала старые', value: 'asc' },
  ]

  private filter: IOrdersProfileFilter = {
    ...GET_DEFAULT_TABLE_FILTER(),
    sortOrder: 'desc', // asc
    year: '',
  }

  private get isReachedLastPage () {
    return this.filter.page === this.ordersMeta.lastPage
  }

  private get orders () {
    return MasterOrdersModule.orders.data
  }

  private get ordersMeta () {
    return MasterOrdersModule.orders.meta
  }

  private appendNewOrders (response: MasterOrderShortResourcePaginator) {
    this.openedOrders = [...this.openedOrders]
    this.ordersFull = [...this.ordersFull, ...response.data]
  }

  private resetOrders () {
    this.filter.page = 1
    this.openedOrders = []
    this.ordersFull = []
  }

  private created () {
    this.fetchOrders()
  }

  private fetchOrders () {
    MasterOrdersModule.fetchOrders(this.filter)
      .then((response) => this.appendNewOrders(response))
      .catch(this.notifyError)
  }

  private handleToggleMore () {
    if (this.filter.page !== this.ordersMeta.lastPage) {
      this.filter = {
        ...this.filter,
        page: this.filter.page + 1,
      }
      this.fetchOrders()
    } else {
      this.resetOrders()
      this.fetchOrders()
    }
  }

  private isOrderWait (order: MasterOrderShortResource) {
    return OrderStatus.WAIT === order.status.value
  }

  private changeOrder(order: MasterOrderLargeResource) {
    if (this.orderMoreInfo[order.id]) {
      this.orderMoreInfo = {
        ...this.orderMoreInfo,
        [order.id]: order,
      }

      this.ordersFull = this.ordersFull.map(item => {
        if (item.id === order.id) {
          return {
            cashbox: order.cashbox,
            createdAt: order.createdAt,
            id: order.id,
            isPaidByThirdParty: order.isPaidByThirdParty,
            originalPrice: order.originalPrice,
            paidAt: order.paidAt ?? '',
            status: order.status,
            totalPrice: order.totalPrice,
            user: order.user,
            uuid: order.uuid,
          }
        }
        return item
      })
    }
  }

  private handlePay(orderUUID: string, id: number) {
    const agreement = this.agreements.find(item => item.uuid === orderUUID)?.value
    if (agreement) {

      if (agreement.agreementOffer) {
        this.orderIDSelected = id
        this.orderUUIDSelected = orderUUID
        if (this.orderMoreInfo[this.orderIDSelected] && this.orderMoreInfo[this.orderIDSelected].totalPrice) {
          this.showOrderPaymentMethodModal = true
        } else {
          this.handleSubmit('default')
        }
      } else {
        this.notifyError('Необходимо принять договор оказания услуг')
      }
    }
  }

  @Bind
  @Debounce(500)
  private handleSubmit(method: 'default' | 'sbp') {
    if (this.orderUUIDSelected && this.orderIDSelected)
      AuthModule.agreement({
        agreements: this.orderMoreInfo[this.orderIDSelected].sales.length || this.orderMoreInfo[this.orderIDSelected].promoCode ? [AgreementsTypeEnum.OFFER_AGREEMENT, AgreementsTypeEnum.SALE_PROMOTION_AGREEMENT] : [AgreementsTypeEnum.OFFER_AGREEMENT],
      })
        .then(() => {
          window.location.replace(`${process.env.VUE_APP_BACKEND_API_ENDPOINT}/payment/orders/${this.orderUUIDSelected}/pay${this.orderMoreInfo[this.orderIDSelected as number].isInitRecurringPayment ? `?is_recurrent=true&payment_system=${method}` : `?payment_system=${method}`}`)
        })
  }

  private loadGoods (index: number, order: MasterOrderShortResource) {
    if (this.openedOrders.includes(index)) {
      this.openedOrders = this.openedOrders.filter(item => item !== index)
      this.agreements = this.agreements.filter(item => item.uuid !== order.uuid)
      return
    }

    if (this.orderMoreInfo[order.id]) {
      this.openedOrders.push(index)
      this.agreements.push({
        uuid: order.uuid,
        value: {
          agreementOffer: false,
        },
      })
      return
    }

    MasterOrdersModule.fetchOrder(order.id)
      .then((response) => {
        this.openedOrders.push(index)
        this.agreements.push({
          uuid: order.uuid,
          value: {
            agreementOffer: false,
          },
        })
        this.orderMoreInfo[response.id] = response
      })
      .catch(this.notifyError)
  }

  private handleFilterYears (year: NameValueResource) {
    this.filter.year = year.value as string
  }

  private metaInfo (): MetaInfo {
    return {
      title: 'Оплаты',
    }
  }

  @Watch('filter.sortOrder')
  watchFilter () {
    this.resetOrders()
    this.fetchOrders()
  }

  @Watch('filter.year')
  watchYear () {
    this.resetOrders()
    this.fetchOrders()
  }
}
